<template>
    <Pie :chart-data="chartData" :chart-options="options" />
</template>

<script>

import { Pie } from 'vue-chartjs'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels)

export default {
    name: "PieChart",
    components: {
        Pie
    },
    props: {
        labels: {
            type: Array,
            default: () => []
        },
        datasets: {
            type: Array,
            default: () => []
        },
        noTitle: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        options(vm = this) {
            return {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: !vm.noTitle,
                    },
                    datalabels: {
                        display: true,
                        color: function(ctx) {
                            return ctx.dataIndex === 2 ? '#1a1a1a' : '#FFFFFF'
                        },
                        font: {
                            weight: 'bold',
                            size: 16,
                        },
                        textAlign: 'center',
                        formatter: (value, ctx) => {
                            return [value, ctx.chart.data.labels[ctx.dataIndex]];
                        }
                    }
                }
            }
        },
        chartData(vm = this) {
            return {
                labels: vm.labels,
                datasets: vm.datasets
            }
        }
    }
};
</script>