<template>
    <v-row v-if="statistic">
        <v-col cols="10" md="11" class="p-relative pa-1">
            <div class="icon-container">
                <v-icon color="#FFFFFF" v-text="statistic.icon"/>
            </div>
            <div class="text-container">
                {{ statistic.name }}
            </div>
        </v-col>
        <v-col cols="2" md="1" class="pa-1 pl-0">
            <div class="count-container">
                {{ statistic.count }}
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "StatisticsCount",
    props: {
        statistic: {
            type: Object,
            default: null,
        }
    }
}
</script>

<style scoped>

.icon-container {
    position: absolute;
    top: 0;
    background-color: var(--v-primary-base);
    padding: 8px;
    border-radius: 50%;
}

.text-container {
    background-color: var(--v-ternary-base);
    color: var(--v-primary-base);;
    padding: 4px 4px 4px 36px;
    margin-left: 12px;
}

.count-container {
    background-color: var(--v-secondary-base);
    text-align: right;
    padding: 4px 12px 4px 4px;
    border-radius: 0 12px 12px 0;
    color: #FFFFFF;
    font-weight: bold;
}

</style>